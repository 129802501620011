import classNames from 'classnames'
import { ReactNode } from 'react'

export const PageNumber = ({
  children,
  isCurrentPage,
  onClick,
  disabled,
}: {
  children: ReactNode
  isCurrentPage?: boolean
  onClick?: () => void
  disabled?: boolean
}) => (
  <button
    className={classNames('inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ', {
      'border-blue-500 text-blue-600': isCurrentPage,
      'border-transparent text-gray-500 ': !isCurrentPage,
      'hover:border-gray-300 hover:text-gray-700 cursor-pointer': !disabled && !isCurrentPage,
      'cursor-not-allowed': disabled,
    })}
    onClick={onClick}
    disabled={disabled}
    aria-current={isCurrentPage ? 'page' : undefined}
  >
    {children}
  </button>
)
