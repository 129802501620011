export const calculateDisplayedTotalPages = (
  displayedPageNumber: number,
  totalPages: number,
  currentPage: number
): number[] => {
  const pages = []
  if (totalPages <= displayedPageNumber) {
    for (let index = 1; index <= totalPages; index++) {
      pages.push(index)
    }
  } else {
    const half = Math.ceil(displayedPageNumber / 2)
    /*
     * Displayed pages: 6
     * Total pages: 10
     * Current page: 1
     * 1 2 3 ... 8 9 10
     */
    if (currentPage <= half) {
      for (let index = 1; index <= half; index++) {
        pages.push(index)
      }
      pages.push(Infinity)

      for (let index = displayedPageNumber - pages.length; index >= 0; index--) {
        pages.push(totalPages - index)
      }
      /*
       * Displayed pages: 6
       * Total pages: 10
       * Current page: 6
       * 1 ... 6 7 8 9 10
       */
    } else if (currentPage > totalPages - half) {
      pages.push(1)
      pages.push(Infinity)
      for (let index = totalPages - displayedPageNumber + 2; index <= totalPages; index++) {
        pages.push(index)
      }
      /*
       * Displayed pages: 6
       * Total pages: 10
       * Current page: 5
       * 1 ... 5 6 7 ... 10
       */
    } else {
      pages.push(1)
      pages.push(Infinity)
      for (let index = currentPage - half + 2; index <= currentPage + half - 2; index++) {
        pages.push(index)
      }
      pages.push(Infinity)
      pages.push(totalPages)
    }
  }
  return pages
}
