import { DirtyIssue } from '@/features/issues'
import { useLayout } from '@/features/layout'
import { scrollToEntryError } from '@/features/tasks'
import { FormikErrors, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EntryCreateInput } from '../../interfaces'
import { CenteredPagination } from '../CenteredPagination'
import { EntryField } from '../EntryField'
import { EntryFieldExtensionProvider } from '../EntryFieldExtensionProvider'
import { SectionPaginatedEntryField } from '../fields/SectionPaginatedEntryField'
import { EntryFieldProps } from '../interfaces'
import { DEFAULT_ENTRIES_FIELDSET_PAGE_SIZE } from './constants'
import { useEntryPages } from './hooks'
import { EntryFieldSetItem } from './interfaces'

interface EntryFieldSetProps<I> extends Pick<EntryFieldProps, 'fileOptions' | 'documentOptions'> {
  items: I[]
  onPageChange?: (currentPage: number, pages: number) => void
}

type FormValues = {
  entries: Array<EntryCreateInput>
  issues: DirtyIssue[]
}

export const PaginatedEntryFieldSet = ({
  items,
  onPageChange,
  ...entryFieldProps
}: EntryFieldSetProps<EntryFieldSetItem>) => {
  const { t } = useTranslation('nsCommon', { keyPrefix: 'pagination' })

  const { scrollToTop } = useLayout()
  const { values, setTouched } = useFormikContext<FormValues>()

  const [pageIndex, setPageIndex] = useState(0)

  const { totalCount, pages } = useEntryPages({ items })

  const page = pages[pageIndex]

  // Trigger onPageChange when pageIndex or pages.length changes
  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex, pages.length)
    }
  }, [pageIndex, pages.length, onPageChange])

  const entriesToTouch = (touched: boolean) => ({
    entries: values.entries.map(() => {
      return { value: touched, values: touched }
    }),
    issues: values.issues.map(issue => {
      return {
        entries: issue.entries.map(() => ({
          value: touched,
          values: touched,
        })),
      }
    }),
  })

  const verifyEntryValues = async (callbackFunction: Function) => {
    const stepErrors = (await setTouched(entriesToTouch(true))) as unknown as {
      entries?: FormikErrors<EntryCreateInput>[]
      issues?: FormikErrors<DirtyIssue>[]
    }

    // Scroll to first failed entry or issue entry
    const hasScrollToSomeFail: boolean = scrollToEntryError(stepErrors.entries, stepErrors.issues)

    if (!hasScrollToSomeFail) {
      callbackFunction()
      setTouched(entriesToTouch(false))
    }
  }

  const handleNextPage = () => {
    verifyEntryValues(() => {
      setPageIndex(pageIndex + 1)
      scrollToTop()
    })
  }

  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1)
    scrollToTop()
  }

  const handleChangePage = (pageNumber: number) => {
    // Verify only if the new page if greater than the current page
    if (pageNumber > pageIndex) {
      verifyEntryValues(() => {
        setPageIndex(pageNumber)
        scrollToTop()
      })
    } else {
      setPageIndex(pageNumber)
      scrollToTop()
    }
  }

  return (
    <div className='space-y-6'>
      <EntryFieldExtensionProvider components={{ section: SectionPaginatedEntryField }}>
        {page.map(({ entryId, entryIndex, sectionEntries, sectionChunkIndex }) => (
          <EntryField
            {...{ ...entryFieldProps, sectionEntries, sectionChunkIndex }}
            key={`entry_${entryId}`}
            index={entryIndex}
          />
        ))}
      </EntryFieldExtensionProvider>
      <CenteredPagination
        pageIndex={pageIndex}
        onPageChange={handleChangePage}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        totalCount={totalCount}
        pageSize={DEFAULT_ENTRIES_FIELDSET_PAGE_SIZE}
        totalPages={pages.length}
        previousText={t('previous')}
        nextText={t('next')}
      />
    </div>
  )
}
