import Config from '@/config'
import { ConfigCatProvider, PollingMode } from 'configcat-react'
import { ReactNode } from 'react'
import { POLL_INTERVAL, REQUEST_TIMEOUT } from './constants'

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  if (!Config.CONFIG_CAT_SDK_KEY) {
    return <>{children}</>
  }

  return (
    <ConfigCatProvider
      sdkKey={Config.CONFIG_CAT_SDK_KEY}
      pollingMode={PollingMode.AutoPoll}
      options={{
        requestTimeoutMs: REQUEST_TIMEOUT,
        pollIntervalSeconds: POLL_INTERVAL,
      }}
    >
      {children}
    </ConfigCatProvider>
  )
}
