import { FoodCategories } from '../foodAlerts'

export enum ProductMetadataItemType {
  TEXT = 'text',
}

export interface ProductMetadataItem {
  key: string
  type: ProductMetadataItemType
  value: string
}

export interface Product {
  _id: string
  companyId: string
  reference: string
  name: string
  description: string
  /** @deprecated Use media */
  image: string | null
  media: { url: string; mimeType: string }[]
  createdAt: string
  updatedAt: string
  attachedFormula?: {
    productionFormulaId?: string
    categoryIds?: string[]
  }
  trus: number
  measurementUnit?: string | null
  minimumProductWeight?: string | null
  stockThreshold?: number | null
  stock?: boolean
  quantity?: string

  facilityId?: string | null

  metadata?: ProductMetadataItem[]
  tags: string[]
  category?: FoodCategories
}

export interface ProductCreation {
  reference: string
  name: string
  description: string
  /** @deprecated Use media */
  image?: { path: string; mimeType: string }
  media?: { url: string; mimeType: string }[]
  measurementUnit?: string
  minimumProductWeight?: string
  stockThreshold?: number
  attachedFormula: {
    productionFormulaId?: string
    categoryIds?: string[]
  }
  facilityId?: string | null
  metadata?: ProductMetadataItem[]
  tags?: string[]

  category?: FoodCategories

  /**
   * Stakeholder product and product ids
   * Create ProductOrigin for each
   */
  originIds?: string[]
}

export interface ProductUpdate {
  name?: string
  description?: string
  image?: { path: string; mimeType: string }
  media?: { url: string; mimeType: string }[]
  _id: string
  measurementUnit?: string
  minimumProductWeight?: string | null
  stockThreshold?: number | null
  attachedFormula: {
    productionFormulaId?: string
    categoryIds?: string[]
  }
  facilityId?: string | null
  metadata?: ProductMetadataItem[] | null
  tags?: string[]
  category?: FoodCategories | null

  /**
   * Stakeholder product and product ids
   * Replace ProductOrigin for each
   */
  originIds?: string[]
}

export interface ProductTransversal {
  company: string
  identifier: string
}

export interface IProductFilters {
  search?: string
  tags?: string[]
  hasCategory?: boolean
}
export type ProductFiltersState = Required<IProductFilters>
