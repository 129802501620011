import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { PageInterval } from './PageInterval'
import { PageNumber } from './PageNumber'
import { calculateDisplayedTotalPages } from './utils'

export interface CenteredPaginationProps {
  /**
   * Current page
   */
  pageIndex: number
  /**
   * Items by page
   * @deprecated Use pageSize instead
   */
  pageSize: number

  /**
   * Total of items
   * @deprecated Use totalPages instead
   */
  totalCount: number

  /**
   * Total of pages
   */
  totalPages: number

  /**
   * Disable next page
   */
  disableNext?: boolean

  /**
   * Function to call when the next page is clicked
   */
  onNextPage: () => void

  /**
   * Disable previous page
   */
  disablePrevious?: boolean
  /**
   * Function to call when the previous page is clicked
   */
  onPreviousPage: () => void

  /**
   * Function to call when the number is clicked
   */
  onPageChange: (page: number) => void

  previousText: ReactNode
  nextText: ReactNode

  /**
   * Number of pages to display
   * @default 6
   */
  displayedPageNumber?: number
}
export const CenteredPagination = ({
  pageIndex,
  totalPages,
  displayedPageNumber = 6,
  onPageChange,
  nextText,
  onNextPage,
  onPreviousPage,
  previousText,
  disablePrevious,
  disableNext,
}: CenteredPaginationProps) => {
  const displayPages = useMemo(
    () => calculateDisplayedTotalPages(displayedPageNumber, totalPages, pageIndex + 1),
    [displayedPageNumber, pageIndex, totalPages]
  )
  return (
    <nav className='flex items-center justify-between border-t border-gray-200 px-4 sm:px-0'>
      {/* Previous */}
      <div className='-mt-px flex w-0 flex-1'>
        {pageIndex > 0 && (
          <button
            className={classNames(
              'inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium',
              {
                'cursor-not-allowed text-gray-300': disablePrevious,
                'cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700':
                  !disablePrevious,
              }
            )}
            onClick={onPreviousPage}
            disabled={disablePrevious}
          >
            <ArrowNarrowLeftIcon className='mr-3 h-5 w-5 text-gray-400' aria-hidden='true' />
            {previousText}
          </button>
        )}
      </div>

      {/* Pages */}
      <div className='hidden md:-mt-px md:flex'>
        {displayPages.map((page, index) => {
          if (page === Infinity) {
            return <PageInterval key={`page-${page}-${index}`} />
          } else {
            const disabled =
              (page - 1 > pageIndex && disableNext) || (page - 1 < pageIndex && disablePrevious)
            return (
              <PageNumber
                key={`page-${page}-${index}`}
                isCurrentPage={page === pageIndex + 1}
                onClick={() => onPageChange(page - 1)}
                disabled={disabled}
              >
                {page}
              </PageNumber>
            )
          }
        })}
      </div>

      {/* Next */}
      <div className='-mt-px flex w-0 flex-1 justify-end'>
        {pageIndex < totalPages - 1 && (
          <button
            className={classNames(
              'inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ',
              {
                'cursor-not-allowed text-gray-300': disableNext,
                'cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700':
                  !disableNext,
              }
            )}
            onClick={onNextPage}
            disabled={disableNext}
          >
            {nextText}
            <ArrowNarrowRightIcon className='ml-3 h-5 w-5 text-gray-400' aria-hidden='true' />
          </button>
        )}
      </div>
    </nav>
  )
}
